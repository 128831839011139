<template>
  <div class="mx-4 mt-4 cursor-pointer overflow-hidden bg-gray-800 shadow-lg">
    <div class="flex w-full flex-col justify-center border-l-4 border-l-blue-600 p-3 pr-5 pt-2">
      <div class="flex w-full justify-end">
        <button @click.stop="close(notification.id)" class="flex text-gray-400 hover:text-gray-100 focus:outline-none">
          <XMarkIcon class="h-4 w-4"></XMarkIcon>
        </button>
      </div>
      <div class="mb-1 flex items-center space-x-2" @click="openChatroom()">
        <div class="flex items-center justify-center" :class="notificationChannels.length == 1 ? 'w-1/9' : 'w-1/5'">
          <ChatImage
            v-if="remainingChannels > 0"
            :item="{ name: '+' + remainingChannels }"
            class="avatar cursor-pointer border border-gray-500"
            size="sm"
          />
          <ChatImage
            v-for="channel in filteredChannels"
            :key="channel.id"
            :item="channel"
            :is-channel="true"
            :channel-name="getChannelName(channel)"
            size="sm"
            class="avatar cursor-pointer border border-gray-500"
          />
        </div>
        <div class="flex flex-1 flex-col">
          <div class="line-clamp-1 text-xs font-medium uppercase tracking-wider text-gray-400">
            {{ notification.payload.title }}
          </div>
          <div class="mb-0.5 flex items-center justify-between">
            <span class="line-clamp-1 w-5/6 text-xs font-medium leading-4">{{ channelNames.join(', ') }}</span>
            <span class="whitespace-nowrap text-xs font-medium leading-4"> Just now </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import ChatImage from '../chat/ChatImage.vue';
import useEmitter from '../../composeables/emitter';
import { getChannelName } from '@/composeables/chat';
import { XMarkIcon } from '@heroicons/vue/20/solid';
const emitter = useEmitter();

const props = defineProps({
  notification: {
    type: Object
  },
  close: {
    type: Function
  }
});
const notificationChannels = ref(props.notification.payload.channels);
const channelNames = ref([]);
const filteredChannels = ref([]);
const remainingChannels = ref(0);

onMounted(() => {
  notificationChannels.value.forEach(async channel => {
    let channelName = await getChannelName(channel);
    channelNames.value.push(channelName);
  });
  if (notificationChannels.value.length > 3) {
    remainingChannels.value = notificationChannels.value.length - 2;
    filteredChannels.value = notificationChannels.value.slice(0, 2);
  } else {
    remainingChannels.value = notificationChannels.value.length - 3;
    filteredChannels.value = notificationChannels.value.slice(0, 3);
  }
});

// methods
async function openChatroom() {
  if (!window.location.pathname.includes('/messenger')) {
    if (props.notification.payload.message) {
      window.open(`/messenger?channel=${notificationChannels.value[0].id}`);
    }
  } else {
    emitter.$emit('channel:change', notificationChannels.value[0]);
  }
  props.close(props.notification.id);
}
</script>
